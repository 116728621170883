import Close from './icons/close';
import FullScreen from './icons/fullscreenicon';
import { Link } from "react-router-dom";
import MinFullScreen from './icons/minfullscreen';
import _ from 'lodash';

function BoardControls(props) {

  const writeBreadCrumb = (bcString, url) => {
    let breadcrumbs = bcString.trim().split("/").filter(s => s !== "");
    breadcrumbs = _.take(breadcrumbs, breadcrumbs.length - 1);
    let anchor = bcString;
    if (breadcrumbs.length <= 1) {
      return anchor;
      // selectExhibit
    } else {
      const exhibit = breadcrumbs.shift();
      let urlArray = url.split("/").filter(s => s !== "");
      urlArray = _.take(urlArray, urlArray.length - 1);
      anchor = breadcrumbs.map((bc, i) => {
        let link = "/";
        for (let j = 0; j <= i; j++) {
          link += urlArray[j] + "/";
        }
        return <Link to={"/search" + link} key={bcString}>{bc + " /"}</Link>
      })
      anchor.unshift(exhibit + " / ")

    }
    return anchor;
  }
  return <div className="board-control-bar">
    <span onClick={props.closeCard}><Close className="control close" /></span>
    <span onClick={props.fullScreen}>
      {props.isFullScreen === false ? <FullScreen className="control fullscreen" /> :
        <MinFullScreen className="control fullscreen" />}
    </span>
    <span className="board-title flex-sm-shrink-1 text-truncate">{writeBreadCrumb(props.title, props.link)}</span>

  </div>
}

export default BoardControls;